import { css } from '@emotion/react';

export const wrapper = css`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 6;
  width: 100%;
`;

export const headingWrapper = css`
  position: relative;
  transition: all ease-in-out 0.2s;
  top: 0;
  left: 0;
`;

export const projectsContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const projectWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  min-width: 300px;
  padding: 3rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
`;
