import React from 'react';
import { Flex, Text } from '../../constants/wrappers';
import { nameWrapper } from './Home.css';
import { SectionProps } from '../../utils/types';

import BgEffects from '../../components/BgEffects';

const Home: React.FC<SectionProps> = () => {
  return (
    <Flex
      sx={{
        height: '100vh',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'relative',
      }}
    >
      <BgEffects />
      <Flex
        css={nameWrapper}
        pl={['1rem', '2rem', 4, 5, null]}
        pt={['1rem', '1.2rem', 4, null, null]}
        initial="hidden"
      >
        <Text
          ml={['-4px', null]}
          mb={[0]}
          variant="heading"
          sx={{
            fontFamily: 'Arboria, sans-serif',
            fontWeight: 300,
          }}
        >
          roger yee
        </Text>
        <Flex
          className="subheading"
          sx={{
            flexDirection: ['column', null, 'row', null],
            justifyContent: ['flex-start', null, 'center', null],
            alignItems: ['flex-start', null, 'center', null],
          }}
        >
          <Text variant="subheading">
            <span className="b">front end</span> developer
          </Text>
          <Text
            variant="subheading"
            className="b"
            px={[null, null, 2, 3]}
            sx={{ display: ['none', null, 'block', null] }}
          >
            |
          </Text>
          <Text variant="subheading">
            <span className="b">ui</span> engineer
          </Text>
          <Text
            variant="subheading"
            className="b"
            px={[null, null, 2, 3]}
            sx={{ display: ['none', null, 'block', null] }}
          >
            |
          </Text>
          <Text variant="subheading">
            based in <span className="b">atlanta, ga</span>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default Home;
