import React from 'react';
import { Divider } from 'theme-ui';
import { css } from '@emotion/react';
import { Box, Flex, Text } from 'constants/wrappers';
import { SectionProps } from 'utils/types';
import { useTransform, useViewportScroll } from 'framer-motion';
import { StaticImage as Img } from 'gatsby-plugin-image';
import useMeasure from 'react-use-measure';

const wrapper = css`
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  z-index: 3;
  width: 100%;

  .about-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .moon,
  .stars {
    position: fixed;
  }
`;

const About: React.FC<SectionProps> = () => {
  const [inViewAmount, setInViewAmount] = React.useState(0);
  const { scrollYProgress } = useViewportScroll();
  const [ref, bounds] = useMeasure({
    scroll: true,
  });
  const fadeOut = useTransform(scrollYProgress, [0, 1], [3, -1.5]);

  React.useEffect(() => {
    if (bounds.top > 0) {
      const diff = bounds.height - bounds.top;
      const viewPct = diff / bounds.height;
      setInViewAmount(parseFloat(viewPct.toPrecision(2)));
    }
  }, [bounds]);

  return (
    <Flex css={wrapper} ref={ref}>
      <Box
        className="about-bg"
        style={{
          opacity: inViewAmount,
        }}
      >
        <Img
          src="../../assets/images/about-bg.svg"
          alt="night-bg"
          objectFit="fill"
          placeholder="tracedSVG"
          loading="eager"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
      <Box
        className="moon"
        style={{
          opacity: inViewAmount,
          scale: inViewAmount * 2,
        }}
        sx={{
          maxWidth: ['150px', null, '220px', null, '600px'],
          maxHeight: ['150px', null, '220px', null, '600px'],
          top: ['2rem', null, null, '8rem', null],
          left: ['26.5%', null, '10%', '10rem', '14rem'],
        }}
      >
        <Img
          src="../../assets/images/moon.svg"
          alt="moon"
          // objectFit="fill"
          placeholder="tracedSVG"
          loading="eager"
          style={{ height: '100%', width: '100%' }}
          objectFit="cover"
        />
      </Box>
      <Box
        className="stars"
        style={{
          opacity: inViewAmount * 2,
        }}
        mr={[null, null, null, null, '-25%']}
        sx={{
          top: ['2rem', null, null, '8rem', null],
          // left: ['26.5%', null, null, '14rem', null],
        }}
      >
        <Img
          src="../../assets/images/stars.svg"
          alt="stars"
          objectFit="fill"
          placeholder="tracedSVG"
          loading="eager"
        />
      </Box>
      <Flex
        sx={{
          position: 'relative',
          width: '100%',
          padding: '3rem',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          boxSizing: 'border-box',
          overflow: 'hidden',
          maxWidth: ['400px', '600px', '800px', null, '960px'],
        }}
        style={{
          // @ts-ignore
          opacity: fadeOut,
        }}
      >
        <Flex
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Text variant="title" color="white" sx={{ fontWeight: 'lighter' }}>
            about<span style={{ fontWeight: 'normal' }}>Me</span>
          </Text>
          <Box
            ml={['3px', null, null, '5px']}
            mb={['1.2rem']}
            sx={{
              width: ['2.8rem', '4.8rem'],
              backgroundColor: 'white',
              height: '3px',
            }}
          />
          <Text variant="bodyB" color="white" sx={{ textAlign: 'justify' }}>
            As a musician, I aspire to create music that embodies my genuine
            effort and dedication but, more importantly, imparts an innate
            passion and enjoyment of the entire process.
            <Divider sx={{ border: 'none' }} />
            As a developer, the sentiment holds true.
            <Divider sx={{ border: 'none' }} />
            From conceptualization to design to development, I have to admit;
            {/*<Divider sx={{ border: 'none' }} />*/}
            {/*It is <i>pretty</i> freakin' neat, taking the intangible, whether it*/}
            {/*be spontaneous ideas or ambitious visions, and bringing them to*/}
            {/*<i> life</i>.*/}
            <Divider py={3} sx={{ border: 'none' }} />
            Also, I <i>love</i> cats.
          </Text>
          <Text color="white" mt={3}>
            ✧/ᐠ-ꞈ-ᐟ\
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default About;
