import { css } from '@emotion/react';

export const wrapper = css`
  position: relative;
  z-index: 3;
  border-radius: 5px;
  width: auto !important;
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #eaeaea;
  overflow: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  contain: content;

  .technology {
    margin-right: 1rem;
    max-height: 30px;
    max-width: 30px;
  }

  .css-1ri71l9 {
    margin-top: 0;
  }
`;

export const contentWrapper = css`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
  z-index: 1;
  background-color: white;

  .paragraph {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .hero-image,
  .hero-image > img {
    width: 100%;
  }

  .close-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f07178;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;
    transition: all 0.2s ease-in-out;
    opacity: 0.75;

    .close-icon {
      width: 50px;
      height: 50px;
      z-index: 4;
      cursor: pointer;
      position: relative;
    }

    &:hover {
      opacity: 1;
    }
  }
`;
