import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Box, Container, Flex, Text } from 'constants/wrappers';
import ProjectCard from './ProjectCard';
import { ModalContainer, modalOverlay } from '../work/Work.css';
import Modal from '../../components/Modal';
import { headingWrapper, projectsContainer, wrapper } from './index.css';

const OVERLAY_FADE = {
  visible: { opacity: 1, transition: { duration: 0.25 } },
  hidden: { opacity: 0 },
};

const MODAL_FADE = {
  visible: { opacity: 1, transition: { duration: 0.3 } },
  hidden: { opacity: 0 },
};

const WorkSection: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false);
  const [projectSelected, setProjectSelected] = React.useState('');

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  React.useEffect(() => {
    console.log(isOpen);
    isOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [isOpen]);

  return (
    <Container
      ref={ref}
      css={wrapper}
      px={['3rem', null, null, '5rem']}
      sx={{
        maxWidth: ['400px', '600px', '800px', '960px'],
      }}
    >
      <Box
        css={[
          headingWrapper,
          inView && { position: 'sticky', top: 0, left: 0, width: '100%' },
        ]}
      >
        <Text variant="title" color="white">
          projects
        </Text>
        <Box
          ml={['3px', null, null, '5px']}
          mt={[3]}
          mb={[4, 5, null, null]}
          sx={{
            width: ['2.8rem', '4rem'],
            backgroundColor: 'white',
            height: '3px',
          }}
        />
      </Box>
      <Flex css={projectsContainer}>
        <ProjectCard
          setProjectSelected={setProjectSelected}
          projectSelected="ror"
          imgSrc="https://ryee.s3.us-west-1.amazonaws.com/Portfolio/lol-match-history_cropped.png"
          isOpen={isOpen}
          setOpen={setOpen}
        />
        <ProjectCard
          setProjectSelected={setProjectSelected}
          projectSelected="covetool"
          imgSrc="https://ryee.s3-us-west-1.amazonaws.com/Portfolio/covetool-ing.png"
          isOpen={isOpen}
          setOpen={setOpen}
        />
        <ProjectCard
          setProjectSelected={setProjectSelected}
          projectSelected="relaycars"
          imgSrc="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/relaycars.png"
          isOpen={isOpen}
          setOpen={setOpen}
        />
        <ProjectCard
          setProjectSelected={setProjectSelected}
          projectSelected="hum"
          imgSrc="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/hum-nutrition.png"
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </Flex>

      {isOpen && (
        <ModalContainer
          px={['1rem', 4, 5, 6]}
          py={[4, null, null, 5]}
          pb={[2, null, null, 5]}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={MODAL_FADE}
        >
          <Box
            css={modalOverlay}
            onClick={() => setOpen(false)}
            initial="hidden"
            animate="visible"
            variants={OVERLAY_FADE}
          />
          <Modal
            projectSelected={projectSelected}
            isOpen={isOpen}
            setOpen={setOpen}
          />
        </ModalContainer>
      )}
    </Container>
  );
};

export default WorkSection;
