import React from 'react';
import { Flex, Image, Link } from 'theme-ui';
import { css } from '@emotion/react';
import { ArrowSquareOut } from 'phosphor-react';

const wrapper = css`
  contain: content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
  padding: 0.8rem 0.6rem 0.6rem 0.6rem;
  opacity: 0.75;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #89ddff;
  cursor: pointer;

  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    opacity: 1;
  }

  .link {
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }

  .icon {
    height: 100%;
    width: 100%;
  }
`;

interface MenuButtonsProps {
  github?: string;
  link?: string;
}

const MenuButtons: React.FC<MenuButtonsProps> = (props: MenuButtonsProps) => {
  const { github, link } = props;

  return (
    <Flex css={wrapper} sx={{ zIndex: 4 }}>
      {github && (
        <Link
          className="link"
          href={github}
          target="_blank"
          mx={['5px', null, null, null]}
          sx={{ textDecoration: 'none' }}
          mb="5px"
        >
          <Image
            src="https://cdn.iconscout.com/icon/free/png-256/github-163-761603.png"
            alt="github"
            sx={{
              height: ['22px', '22px', '30px', null],
              width: ['22px', '22px', '30px', null],
            }}
          />
        </Link>
      )}
      {link && (
        <Link
          className="link"
          href={link}
          target="_blank"
          sx={{
            height: ['25px', null, '30px', '40px'],
            width: ['25px', null, '30px', '40px'],
          }}
        >
          <ArrowSquareOut className="icon" color="black" />
        </Link>
      )}
    </Flex>
  );
};

export default MenuButtons;
