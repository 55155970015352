import React from 'react';
import { Box, Container, Flex, Text } from 'theme-ui';
import { StaticImage as Img } from 'gatsby-plugin-image';

import LinkBar from '../../../components/MenuButtons';
// import HumNutritionHero from '../../../assets/images/hum-nutrition_hero.png';

const HumNutrition: React.FC = () => (
  <>
    <Box sx={{ position: 'relative' }}>
      <Flex
        sx={{
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: ['100%', null, null, null],
          zIndex: 3,
          margin: '0 auto',
        }}
      >
        <Img
          src="../../../assets/images/hum-nutrition_hero.png"
          alt="macbook-pro"
          placeholder="tracedSVG"
        />
      </Flex>
    </Box>
    <Container
      pt={[2]}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Text variant="title" sx={{ fontWeight: 'normal' }}>
          HUM Nutrition Landing Page
        </Text>
        <Box sx={{ width: '100%' }}>
          <Img
            className="technology"
            src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/html-icon.png"
            alt="html"
            // imgStyle={{ height: '30px', marginRight: '1rem' }}
            placeholder="tracedSVG"
          />
          <Img
            className="technology"
            src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/css-icon.png"
            alt="css"
            // style={{ height: '30px', marginRight: '1rem' }}
            placeholder="tracedSVG"
          />
          <Img
            className="technology"
            src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/javascript-icon.png"
            alt="js"
            // style={{ height: '30px', marginRight: '1rem' }}
            placeholder="tracedSVG"
          />
        </Box>
      </Flex>
    </Container>
    <LinkBar link="https://www.humnutrition.com/" />
  </>
);

export default HumNutrition;
