import React from 'react';
import { Box, Container, Flex, Image, Text } from 'theme-ui';
import { StaticImage as Img } from 'gatsby-plugin-image';

import LinkBar from '../../../components/MenuButtons';

const ReegOfRegends: React.FC = () => (
  <>
    <Box
      sx={{ position: 'relative', width: '100%', height: '100%' }}
      className="hero-image"
    >
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <Image
          src="https://ryee.s3.us-west-1.amazonaws.com/Portfolio/lol-match-history.gif"
          alt="demo"
          style={{
            width: '100%',
            maxWidth: '800px',
            zIndex: 3,
            boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.2)',
            padding: '1rem',
          }}
        />
      </Container>
    </Box>
    <Container>
      <Flex
        sx={{
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Text variant="title" py={[3]} sx={{ fontWeight: 'normal' }}>
          LoL Match History
        </Text>
        <Flex pb={[4]}>
          <Img
            className="technology"
            src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/typescript-icon.png"
            alt="typescript"
            placeholder="tracedSVG"
          />
          <Img
            className="technology"
            src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/react-icon.png"
            alt="react"
            placeholder="tracedSVG"
          />
          <Img
            className="technology"
            src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/node-icon.png"
            alt="nodejs"
            placeholder="tracedSVG"
          />
        </Flex>
      </Flex>

      <Flex className="paragraph">
        <Text variant="subtitle">Summary</Text>

        <Text variant="bodyB">
          This simple web application utilizes Riot Games' public API to allow
          users to search a summoner`s name and view the inputted summoner`s
          recent match history and respective match stats.
        </Text>
        <Text variant="bodyB" mt={[2]}>
          If you are unfamiliar with League of Legends or don`t know of any
          summoner names to search, feel free to try the summoner names listed
          below.
        </Text>
        <Text variant="bodyB" mt={[2]}>
          Here are the top 5 players in League of Legends of all time:
          <ol>
            <li>Faker</li>
            <li>Rookie</li>
            <li>Uzi</li>
            <li>Caps</li>
            <li>Rekkles</li>
          </ol>
        </Text>
      </Flex>
    </Container>

    <LinkBar
      github="https://github.com/ryee-dev/next-lol-match-history"
      link="https://next-lol-match-history-845zs4s96-yee.vercel.app"
    />
  </>
);

export default ReegOfRegends;
