import { motion } from 'framer-motion';
import {
  Box as BoxBase,
  Container as ContainerBase,
  Flex as FlexBase,
  Image as ImageBase,
  Text as TextBase,
} from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';

const Container = motion(ContainerBase);
const Flex = motion(FlexBase);
const Box = motion(BoxBase);
const Image = motion(ImageBase);
const Text = motion(TextBase);

// const _Img = React.forwardRef((props, ref) => <StaticImage ref={ref} />);

const Img = motion(StaticImage, { forwardMotionProps: true });

// const Container = styled(_Container)``;
// const Flex = styled(_Flex)``;
// const Box = styled(_Box)``;
// const Image = styled(_Image)``;
// const Text = styled(_Text)``;

export { Container, Flex, Box, Image, Text, Img };
