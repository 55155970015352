import React from 'react';
import { css, Global } from '@emotion/react';
import '../assets/fonts/fonts.css';
import { About, Home } from '../sections';
import WorkSection from '../sections/work-v2';
import { Container } from 'constants/wrappers';

const App: React.FC = () => {
  return (
    <>
      <Global
        styles={css`
          html,
          body {
            margin: 0;
            padding: 0;
            font-family: Arboria, sans-serif;
          }

          * {
            box-sizing: border-box;
          }

          html {
            scroll-behavior: smooth;
          }

          body {
            overflow-x: hidden;
          }
        `}
      />
      <Container
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          overflow: 'hidden',
          background: 'white',
        }}
        pb={3}
      >
        <Home />
        <About />
        <WorkSection />
      </Container>
    </>
  );
};

export default App;
