import styled from '@emotion/styled';
import { Container } from 'constants/wrappers';
import { motion } from 'framer-motion';
import { css } from '@emotion/react';

export const wrapper = css`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 2000px;
  overflow: hidden;
  z-index: 3;

  .close-icon {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
  }

  .other-slide {
    height: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 1;

    &:hover {
      cursor: pointer;
      -webkit-transform: scale(1.025, 1.025);
      transform: scale(1.025, 1.025);

      &::after {
        opacity: 0.8;
      }
    }

    .overlay {
      background-color: white;
      height: 100%;
      width: 100%;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

      &:hover {
        opacity: 0.2;
      }
    }

    img {
      position: relative;
      z-index: 1;
    }
  }

  // focused slide
  .css-1fzpoyk {
    opacity: 1 !important;

    &::after {
      content: '';
      border-radius: 5px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
      opacity: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      cursor: pointer;
      -webkit-transform: scale(1.025, 1.025);
      transform: scale(1.025, 1.025);

      &::after {
        opacity: 1;
      }
    }
  }

  .css-1qzevvg {
    position: absolute;
    max-width: 1800px;
    margin: 0 auto;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0 2rem;

    &::after {
      content: '';
      border-radius: 5px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
      opacity: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8 !important;
      -webkit-transform: scale(1.025, 1.025);
      transform: scale(1.025, 1.025);

      &::after {
        opacity: 0.8;
      }
    }
  }
`;

const StyledContainer = styled(Container)`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  box-sizing: border-box;
  overflow: hidden;
  //box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ModalContainer = motion(StyledContainer);

export const modalOverlay = css`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.1s ease-in-out;
  z-index: 1;
`;

const AnimatedSlideWrapper = styled(Container)`
  box-shadow: 0 5px 15px 1px rgb(0 0 0 / 15%);
  border-radius: 5px;
  position: relative;
  display: inline-block;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 100%;

  &::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    opacity: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    cursor: pointer;
    -webkit-transform: scale(1.025, 1.025);
    transform: scale(1.025, 1.025);

    &::after {
      opacity: 1;
    }
  }
`;

export const SlideWrapper = motion(AnimatedSlideWrapper);
