import { css } from '@emotion/react';

export const bgWrapper = css`
  width: 100vw;
  height: 100%;
  position: absolute;
  //top: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 3;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;

  .bg {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
  }

  img {
    &.branch {
      position: relative;
      width: 100%;
      z-index: 4;

      @media (max-width: 1200px) {
        transform: rotate(-10deg) translate(25px, 70px);
      }

      transform: rotate(0deg);
    }
  }

  .bg-overlay {
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: fixed;
    bottom: 1rem;
    left: 0;
    z-index: 2;
  }
`;

export const bgWallpaper = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-image: url('https://d3pvl5xihxptpz.cloudfront.net/Portfolio/paper-bg.png');
  //background-position: center;
  //background-size: cover;
  z-index: 0;
`;

export const mountainStyles = css`
  position: absolute;
  left: 0;
  width: 100%;
  //transform: translateY(10%);
  z-index: 2; ;
`;

export const sunStyles = css`
  position: absolute;
  //top: 16rem;
  //left: 4rem;
  z-index: 3;
`;

export const paperStyles = css`
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;
