import { css } from '@emotion/react';

export const homeWrapper = css`
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;

  .b {
    font-weight: 400;
  }
`;

export const nameWrapper = css`
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

  .b {
    font-weight: 400;
  }

  .subheading {
    justify-content: flex-start;
  }
`;
