import React, { Dispatch, SetStateAction } from 'react';
import { Box, Container } from 'constants/wrappers';
import { X } from 'phosphor-react';

import {
  CoveTool,
  HumNutrition,
  RelayCars,
  RoR,
} from '../../sections/work/projects';
import { contentWrapper, wrapper } from './Modal.css';

interface ModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  projectSelected: string;
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const { setOpen, projectSelected } = props;

  return (
    <Container
      css={wrapper}
      sx={{
        maxWidth: [null, null, '800px', null, '1000px'],
      }}
    >
      <Container
        px={[4, null, 5, null, null]}
        py={[4, null, 4, null, null]}
        // pb={[2, null, 3, 4, null]}
        css={contentWrapper}
      >
        <Box
          className="close-modal"
          onClick={() => setOpen(false)}
          sx={{
            padding: ['0.3rem'],
            height: ['25px', '30px', '40px', null],
            width: ['25px', '30px', '40px', null],
          }}
        >
          <X className="close-icon" color="white" />
        </Box>
        {projectSelected === 'covetool' && <CoveTool />}
        {projectSelected === 'relaycars' && <RelayCars />}
        {projectSelected === 'ror' && <RoR />}
        {projectSelected === 'hum' && <HumNutrition />}
      </Container>
    </Container>
  );
};

export default Modal;
