import React from 'react';
import ReactPlayer from 'react-player';
import { Box, Container, Divider, Flex, Text } from 'theme-ui';
import { StaticImage as Img } from 'gatsby-plugin-image';

// import MBP from '../../../assets/images/mbp.png';

const RelayCars: React.FC = () => (
  <>
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: ['100%', null, null, null],
          zIndex: 3,
          margin: '-1rem auto 2rem auto',
        }}
      >
        <Img
          src="../../../assets/images/mbp.png"
          alt="macbook-pro"
          placeholder="tracedSVG"
        />

        <ReactPlayer
          url="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/relaycars.mp4"
          playing={true}
          loop={true}
          width="76%"
          height="auto"
          style={{
            position: 'absolute',
            bottom: 0,
            zIndex: 3,
            marginBottom: '7%',
          }}
        />
      </Flex>
    </Box>
    <Container
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Text
        variant="title"
        pb={[2]}
        sx={{ width: '100%', fontWeight: 'normal' }}
      >
        The Showroom
      </Text>
      <Text
        variant="subtitle"
        pb={[2]}
        sx={{ width: '100%', fontWeight: 'normal' }}
      >
        RelayCars
      </Text>
      <Divider />
      <Text variant="bodyB" mb={[3]}>
        As a subsidiary of Evox Images, an automotive imaging company that
        boasts the most extensive car stock image library globally, RelayCars
        aims to provide a seamless, immersive 3D vehicle-viewing experience by
        utilizing the latest VR technology.
      </Text>
      <Text variant="bodyB">
        The primary motivation behind the development of the RelayCars Showroom
        web app was to essentially showcase the most popular features of the
        existing VR and mobile applications through the medium of the everyday
        web interface.
      </Text>
    </Container>
    {/*<LinkBar link="https://vr.relaycars.com" />*/}
  </>
);

export default RelayCars;
