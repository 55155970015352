import React from 'react';
import { Box, Flex } from 'constants/wrappers';
import { StaticImage as Img } from 'gatsby-plugin-image';
import {
  bgWallpaper,
  bgWrapper,
  mountainStyles,
  paperStyles,
  sunStyles,
} from './BgEffects.css';
import Petals from './Petals';
import { useTransform, useViewportScroll } from 'framer-motion';

const BgEffects: React.FC = () => {
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //       allImageSharp {
  //         edges {
  //           node {
  //             original {
  //               src
  //             }
  //             gatsbyImageData(placeholder: BLURRED)
  //           }
  //         }
  //       }
  //     }
  //   `
  // );

  const [bgOpacity, setBgOpacity] = React.useState(null);

  const { scrollYProgress } = useViewportScroll();

  const bgBranchLayer = useTransform(scrollYProgress, [0, 1], [0, 400]);
  const fadeRight = useTransform(scrollYProgress, [0, 1], [1, -1]);
  const fadeMd = useTransform(scrollYProgress, [0, 1], [1, -0.2]);
  const mtnFade = useTransform(scrollYProgress, [0, 1], [1, 1800]);
  const mtnScale = useTransform(scrollYProgress, [0, 1], [1, 0.4]);
  const sunFade = useTransform(scrollYProgress, [0, 1], [1, 1800]);
  const sunScale = useTransform(scrollYProgress, [0, 1], [1.2, 3]);
  const mtnOpacity = useTransform(scrollYProgress, [0, 1], [1, -1.2]);

  React.useEffect(() => {
    setBgOpacity(fadeMd);
  }, [fadeMd]);

  return (
    <Flex css={bgWrapper}>
      <Img
        // src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/paper-bg.png"
        src="../../assets/images/paper-bg.svg"
        alt="paper"
        objectFit="fill"
        placeholder="tracedSVG"
        // @ts-ignore
        css={paperStyles}
        loading="eager"
      />
      <Flex
        css={bgWrapper}
        sx={{
          // top: ['30%', '15%', '10%', 0, null],
          bottom: ['-25%', null, '-15%', null, 0],
        }}
      >
        <Box
          className="bg"
          sx={{
            width: '100%',
            marginTop: ['-5.2rem', null, '0rem', '-4rem', null],
            maxWidth: ['380px', '600px', '800px', null, '1000px'],
            zIndex: [2],
          }}
          style={{
            x: bgBranchLayer,
            opacity: bgOpacity,
            zIndex: 3,
            width: '100%',
          }}
        >
          <Img
            className="branch"
            src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/branch.png"
            alt="branch"
            placeholder="tracedSVG"
            imgStyle={{
              width: '100%',
              marginTop: 0,
              zIndex: 2,
            }}
            draggable={false}
            loading="eager"
          />
        </Box>
        <Petals />
        <Box
          css={mountainStyles}
          mt={['2rem', null, null, 0]}
          sx={{
            // top: '25%',
            maxWidth: ['2000px'],
            left: [null, null, null, null, '10%'],
            bottom: ['75%', '50%', '40%', '20%', '10%'],
            transform: 'translateY(-25%)',
          }}
          style={{
            y: mtnFade,
            // @ts-ignore
            scale: mtnScale,
            // @ts-ignore
            opacity: mtnOpacity ? mtnOpacity : 0,
          }}
        >
          <Img
            src="../../assets/images/mountains.png"
            alt="mountains"
            placeholder="tracedSVG"
            loading="eager"
          />
        </Box>
        <Box
          css={sunStyles}
          sx={{
            maxWidth: ['150px', null, '220px', null, '400px'],
            maxHeight: ['150px', null, '220px', null, '400px'],
            top: ['2rem', null, null, '16rem'],
            left: ['26.5%', null, '10%', '10rem', '14rem'],
          }}
          style={{
            y: sunFade,
            // @ts-ignore
            scale: sunScale,
          }}
        >
          <Img
            src="../../assets/images/sun.svg"
            alt="sun"
            placeholder="tracedSVG"
            loading="eager"
            style={{ height: '100%', width: '100%' }}
            objectFit="cover"
          />
        </Box>
      </Flex>
      <Box css={bgWallpaper} />
    </Flex>
  );
};

export default BgEffects;
