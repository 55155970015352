import React, { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { Container } from 'constants/wrappers';

const cardStyles = css`
  font-family: graphie, sans-serif;
  //box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 15px 2px rgb(0 0 0 / 40%);
  width: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  border-radius: 24px;
  padding: 2rem;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  z-index: 3;

  .bg-image {
    height: 100%;
    width: 100%;
  }

  &:hover {
    transform: scale(1.005);
  }
`;

interface CardProps {
  projectSelected: string;
  setProjectSelected: Dispatch<SetStateAction<string>>;
  imgSrc: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

const ProjectCard = ({
  setOpen,
  projectSelected,
  setProjectSelected,
  imgSrc,
}: CardProps) => {
  const handleSelectProject = (project) => {
    setOpen(true);
    setProjectSelected(project);
  };

  return (
    <Container
      onClick={() => handleSelectProject(projectSelected)}
      css={cardStyles}
      sx={{
        background: `url(${imgSrc}) center`,
        backgroundSize: 'cover',
        aspectRatio: '16/9',
        maxWidth: [null, null, '500px', null, '600px'],
      }}
    />
  );
};

export default ProjectCard;
