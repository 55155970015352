import React from 'react';
import ReactPlayer from 'react-player';
import { Box, Container, Divider, Flex, Text } from 'theme-ui';
import { StaticImage as Img } from 'gatsby-plugin-image';
import MenuButtons from '../../../components/MenuButtons';
// import MBP from '../../../assets/images/mbp.png';

const CoveTool: React.FC = () => {
  return (
    <>
      <Box sx={{ position: 'relative', width: '100%' }} className="hero-image">
        <Flex
          sx={{
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            width: ['100%', null, null, null],
            zIndex: 3,
            margin: [
              null,
              null,
              '0 auto 2rem auto',
              '-1rem auto 2rem auto',
              null,
            ],
          }}
        >
          <Box sx={{}}>
            <Img
              src="../../../assets/images/mbp.png"
              alt="macbook-pro"
              placeholder="tracedSVG"
              style={{ width: '100%' }}
            />
          </Box>

          <ReactPlayer
            url="https://ryee.s3-us-west-1.amazonaws.com/Portfolio/Tab-1614247741093.webm"
            playing={true}
            loop={true}
            width="76%"
            height="auto"
            style={{
              position: 'absolute',
              bottom: 0,
              zIndex: 3,
              marginBottom: '8.75%',
            }}
          />
        </Flex>
      </Box>
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Flex
          sx={{
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Text variant="title" sx={{ width: '100%', fontWeight: 'normal' }}>
            drawing.tool
          </Text>
          <Text variant="subtitle" sx={{ width: '100%', fontWeight: 'normal' }}>
            cove.tool
          </Text>
          <Divider />
          <Text
            variant="subtitle"
            pb={[3]}
            sx={{ width: '100%', fontWeight: 'normal' }}
          >
            Summary
          </Text>
          <Text
            variant="bodyB"
            mb={[3]}
            sx={{ position: 'relative', zIndex: 3 }}
          >
            Cove Tool's primary product consists of a suite of powerful tools
            that utilize advanced automated workflows in providing key analytics
            regarding buildings and their inherent architecture. These analytics
            take a variety of precise parameters such as building material,
            window thickness, sunlight/UV exposure, among a myriad of other
            factors, in providing accurate building optimization strategies.
          </Text>

          <Text
            variant="subtitle"
            py={[3]}
            sx={{ width: '100%', fontWeight: 'normal' }}
          >
            Motivation
          </Text>
          <Text
            variant="bodyB"
            mb={[3]}
            sx={{ position: 'relative', zIndex: 3 }}
          >
            Previously, in order to take advantage of the previously mentioned
            automation workflows, a 3D model would first need to be exported by
            third-party software such as Revit, SketchUp, or Rhinoceros and then
            imported into the web application. This intermediary step was
            ultimately an unavoidable barrier for non-architects (designers,
            engineers, developers, etc.) who may not be as familiar with those
            pieces of software. The goal for the drawing tool was to alleviate
            the barrier while maintaining as much of the core functionality of
            the aforementioned software. We wanted the in-app drawing tool to
            generate rudimentary models that provided all the data points and
            parameters necessary. Still, we also wanted to create an
            aesthetically pleasing user interface without sacrificing the end
            user experience.
          </Text>
        </Flex>
      </Container>
      <MenuButtons link="https://www.cove.tools/" />
    </>
  );
};

export default CoveTool;
