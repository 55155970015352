import React from 'react';
import { css } from '@emotion/react';
import { Box, Container } from 'constants/wrappers';
import { StaticImage as Img } from 'gatsby-plugin-image';
import { useTransform, useViewportScroll } from 'framer-motion';

const wrapper = css`
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 5;
  max-width: 1600px;

  .petals {
    width: 100%;
    z-index: 1;
    position: absolute;
  }
`;

const Petals: React.FC = () => {
  const { scrollYProgress } = useViewportScroll();
  const y1 = useTransform(scrollYProgress, [0, 0.7, 1], [0, 1800, 3000]);
  const y2 = useTransform(scrollYProgress, [0, 0.6, 1], [0, 1500, 2800]);
  const y3 = useTransform(scrollYProgress, [0, 0.8, 1], [0, 1670, 2900]);
  const x1 = useTransform(scrollYProgress, [0, 1], [0, -1200]);
  const fadeOut = useTransform(scrollYProgress, [0, 0.5, 1], [1, 0.3, 0.1]);

  return (
    <Container
      css={wrapper}
      className="petals-wrapper"
      mt={['0.4rem', null, 2, 0]}
      mr={[0, null, -3, 0]}
      sx={{
        transform: ['rotate(0deg)', null, null, null],
        top: ['4rem', '8rem', '16rem', null, null],
        maxWidth: [null, null, '1000px', '1500px'],
      }}
      style={{
        // @ts-ignore
        opacity: fadeOut,
        x: x1,
      }}
    >
      <Box style={{ y: y1 }}>
        <Img
          className="petals"
          alt="petals-layer-1"
          src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/petals-layer_1.png"
          placeholder="tracedSVG"
        />
      </Box>
      <Box style={{ y: y2 }}>
        <Img
          className="petals"
          alt="petals-layer-2"
          src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/petals-layer_2.png"
          placeholder="tracedSVG"
        />
      </Box>
      <Box style={{ y: y3 }}>
        <Img
          className="petals"
          alt="petals-layer-3"
          src="https://d3pvl5xihxptpz.cloudfront.net/Portfolio/petals-layer_3.png"
          placeholder="tracedSVG"
        />
      </Box>
    </Container>
  );
};

export default Petals;
